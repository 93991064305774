:root {
  --main-green-color: #38902B;
}

html {
  height: 100%;
}

#root {
  font-family: Inter;
  overflow-x: hidden;
  overflow-y: auto;
  user-select: none;
}

body {
  height: 100%;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}

.menu {
  max-height: 0;
  transition: max-height 0.3s ease-out;
  overflow: hidden;
}

.menu-transition {
  max-height: 374px;
  transition: max-height 0.3s ease-out;
}

.sub-menu-transition {
  max-height: 90px;
  transition: max-height 0.3s ease-out;
}

nav a {
  font-size: 1rem/* 16px */;
  line-height: 1.5rem/* 24px */;
  transition-duration: 300ms;
  color: black;
}

nav a .submenu div {
  font-weight: 400 !important;
}

h2 {
  font-size: 1.875rem!important;/* 30px */;
  line-height: 2.25rem/* 36px */;
  font-weight: 600;
}

h3 {
  font-size: 1.5rem!important;/* 24px */;
  line-height: 2rem!important;/* 32px */;
  font-weight: 600 !important;
}

p {
  font-weight: 400;
  line-height: 2;
}

@media screen and (min-width: 640px) {
  p {
    font-size: 1.125rem/* 18px */;
  }
}

@media screen and (max-width: 640px) {
  h2 {
    font-size: 1.5rem!important;/* 24px */;
  }
  h3 {
    font-size: 1.25rem!important;/* 24px */;
  }
  p {
    font-size: 0.875rem/* 14px */;
  }
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #FFF 3.08%, rgba(255, 255, 255, 0.00) 6.52%);
}

.gradient-overlay-white {
  background: linear-gradient(0deg, #FFF 3.08%, rgba(255, 255, 255, 0.00) 6.52%);
}

.gradient-overlay-green {
  background: linear-gradient(0deg, var(--main-green-color) 1%, rgba(255, 255, 255, 0.00) 7.86%);
}

.gradient-overlay-obs-first {
  background: linear-gradient(359deg, rgba(255, 255, 255, 0.00) 92.76%, #FFF 96.3%), linear-gradient(359deg, var(--main-green-color) 5.18%, rgba(255, 255, 255, 0.00) 8.9%);
}

.gradient-overlay-obs-mid {
  background: linear-gradient(359deg, rgba(255, 255, 255, 0.00) 92.76%, var(--main-green-color) 96.3%), linear-gradient(359deg, var(--main-green-color) 5.18%, rgba(255, 255, 255, 0.00) 8.9%);
}

.slide-in {
  transform: translateX(0%);
}

.slide-out {
  transform: translateX(-100%);
}

.dropdown:hover .item {
  color: var(--main-green-color);
}

.dropdown:hover .dropdown-item:hover a {
  color: var(--main-green-color);
}

.dropdown-container {
  max-height: 0;
  transition: max-height 0.3s ease-out;
  transform: translateX(-25%);
}

.dropdown:hover .dropdown-container {
  max-height: 300px;
  transition: max-height 0.3s ease-out;
}

.dropdown .dropdown-content div {
  opacity: 0;
}

.dropdown:hover .dropdown-content div {
  opacity: 100;
}

.active {
  color: var(--main-green-color);
}

ul li {
  list-style-type: disc;
  margin-left: 30px;
}

.full-shadow {
  box-shadow: 0 0 23px 0 rgba(0,0,0,0.15) !important;
}

.full-shadow-green {
  box-shadow: 0 0 23px 0 var(--main-green-color) !important;
}